export const _Icon = ({
  Icon,
  end = false,
  disabled = false,
  color = "secondary.on.container"
}) => <Icon sx={style(end, disabled, color)} />;

export const style =
  (end = false, disabled = false, color = "secondary.on.container") =>
  theme => ({
    width: 18,
    height: 18,
    ml: end ? 0.5 : -1.5,
    mr: end ? -1.5 : 0.5,
    color: disabled
      ? theme.palette.alpha(theme.palette.surface.on.color, 0.38)
      : color
  });
